import axios from 'axios';

const BASE_URL = process.env.VUE_APP_APIURL;

const getHistory = (reportId, options = {
    pageSize: 10, pageNum: 1,
}) => axios.get(`${BASE_URL}/api/Archive/Reports/${reportId}?pageSize=${options.pageSize}&pageNum=${options.pageNum}`);

export default {
    getHistory,
};
